import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { store as uiState } from '../../ui'
import { store as contextStore } from '../../context'
import { CMToolbar as LinkToolbar } from '../../link'
import { CMToolbar as ImageToolbar } from '../../image'
import { CMToolbar as VideoToolbar } from '../../video'
import { CMToolbar as CenshareToolbar } from '../../censhare'
import { Toolbar as ScribeEditorToolbar } from '../../editor/scribe'
import { Toolbar as DraftEditorToolbar } from '../../editor/draft'
import LockStateMenubar from '../../shared/components/LockStateMenubar'
import ToolsSidebarIcon from '../../shared/icons/tools-sidebar'
import { deepGet } from '../../shared/obj'
import connectImageToolsToCommands from '../../shared/connectors/connectImageToolsToCommands'
import connectVideoToolsToCommands from '../../shared/connectors/connectVideoToolsToCommands'
import connectArticleCMToolbarToCommands
  from '../connectors/connectArticleCMToolbarToCommands'
import connectCenshareToolsToCommands from '../../censhare/connectors/connectCenshareToolsToCommands'
import GenevaButton from '../../ui/components/GenevaButton'
import { VersionToolbar } from '../version'

import * as css from '../styles.scss'

const ConnectedImageToolbar = connectImageToolsToCommands(
  ImageToolbar
)
const ConnectedVideoToolbar = connectVideoToolsToCommands(
  VideoToolbar
)

const ConnectedCenshareToolbar = connectCenshareToolsToCommands(
  CenshareToolbar
)

const ConnectedArticleCMToolbar = connectArticleCMToolbarToCommands()

const Context = contextStore.constructor


@observer
export default class MenuBar extends Component {

  // eslint-disable-next-line react/sort-comp
  static tools = [
    'version',
    'comments',
    'research',
    'release',
    'translate'
  ]

  static toolbarDefaults = {
    [Context.CONTEXT_TYPE_ARTICLE]: false,
    [Context.CONTEXT_TYPE_IMAGE]: false,
    [Context.CONTEXT_TYPE_VIDEO]: false,
    [Context.CONTEXT_TYPE_TEXT]: false,
    [Context.CONTEXT_TYPE_LINK]: false,
    [Context.CONTEXT_TYPE_VERSION]: false,
    [Context.CONTEXT_TYPE_CENSHARE]: false,
    template: false
  }

  static propTypes = {
    article: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  constructor(props) {

    super(props)

    this.state = {
      context: null
    }

  }

  componentDidMount() {
    this.determinePaneState(this.props.params.tool)
    // APPROVED: whenever the the context changed, notify of context change
    this.handler = autorun(() => this.contextChanged(
      this.props.context.target, this.props.context.subContexts
    ))
  }

  componentWillReceiveProps(nextProps: Object) {
    if (nextProps.params.tool !== this.props.params.tool) {
      this.determinePaneState(nextProps.params.tool)
    }
  }

  componentWillUnmount() {
    if (this.handler) {
      this.handler()
      this.handler = null
    }
  }

  determinePaneState(tool) {
    const articleToolsPanelOpen = MenuBar.tools.indexOf(tool) >= 0

    uiState.updatePaneState({
      articleToolsPanelOpen
    })
  }


  contextChanged() {

    const { context } = this.props

    let toolbars = MenuBar.toolbarDefaults

    if (this.articleIsOpen()) {

      toolbars = {
        ...MenuBar.toolbarDefaults,
        [context.type]: true,
      }

      context.subContexts.forEach((subContext) => {
        toolbars[subContext.type] = true
      })
    }

    this.setState(toolbars)
  }

  articleIsOpen() {
    return this.props.article.hasCurrent
      && this.props.article.current.complete
  }

  @autobind
  handleChangeToolsPanelVisibility() {

    const { paneState } = uiState

    paneState.articleToolsPanelOpen = !paneState.articleToolsPanelOpen

  }

  /**
   * @private
   * This should prevent focus loss on the text-editor once
   * the user clicks anywhere in the toolbar area.
   * @param {Event} e - The mouse event.
   */
  @autobind
  handleMouseDownOnMenuBar(e) {
    e.preventDefault()
    return false
  }

  render() {

    const { ui, ui: { paneState }, article, project } = this.props

    const hasMainContext = this.state[Context.CONTEXT_TYPE_IMAGE]
      || this.state[Context.CONTEXT_TYPE_TEXT]
      || this.state[Context.CONTEXT_TYPE_VIDEO]

    const isLocked = article.hasCurrent
      && (article.current.isLockedByOther()
      || article.current.isLockedBySystem())

    const ToolbarComp = deepGet(article, 'current.editorVersion') === 'draft' ? DraftEditorToolbar : ScribeEditorToolbar

    return (<div
      className={`menu-bar main-toolbar ${css.articleMenubar}`}
      onMouseDown={this.handleMouseDownOnMenuBar}
    >
      {isLocked && !this.state[Context.CONTEXT_TYPE_VERSION]
        ? <LockStateMenubar
          className={`menu-bar main-toolbar ${css.articleMenubar}`}
          lockable={article}
        />
        : null}

      <div
        className={classNames('menubar-container grid-block',
          { hidden: isLocked && !this.state[Context.CONTEXT_TYPE_VERSION] }
        )}
      >
        <div className="grid-block shrink">
          <ConnectedArticleCMToolbar
            className={classNames(
              'pull-left',
              'article-toolbar',
              { hidden: !article.hasCurrent || this.state[Context.CONTEXT_TYPE_VERSION] }
            )}
            key="article-toolbar"
            ref="article-toolbar"
            options={ConnectedArticleCMToolbar.options}
            ui={ui}
            article={article}
            project={project}
          />
        </div>
        <div className="grid-block align-center toolbar-container">

          <ConnectedImageToolbar
            onMouseDown={this.handleMouseDownOnMenuBar}
            context={this.props.context}
            article={this.props.article}
            className={classNames(
              'pull-left',
              'image-toolbar',
              'responsive-image-toolbar',
              { hidden: !this.state[Context.CONTEXT_TYPE_IMAGE] }
            )}
            key="image-toolbar"
            ref="image-toolbar"
            options={ImageToolbar.options}
          />

          <ConnectedVideoToolbar
            onMouseDown={this.handleMouseDownOnMenuBar}
            context={this.props.context}
            className={classNames(
              'pull-left',
              'image-toolbar',
              { hidden: !this.state[Context.CONTEXT_TYPE_VIDEO] }
            )}
            key="video-toolbar"
            ref="video-toolbar"
            options={VideoToolbar.options}
          />

          <ConnectedCenshareToolbar
            onMouseDown={this.handleMouseDownOnMenuBar}
            context={this.props.context}
            className={classNames(
              'pull-left',
              'censhare-toolbar',
              { hidden: !this.state[Context.CONTEXT_TYPE_CENSHARE] }
            )}
            key="censhare-toolbar"
            ref="censhare-toolbar"
            options={CenshareToolbar.options}
          />

          <ToolbarComp
            onMouseDown={this.handleMouseDownOnMenuBar}
            className={classNames(
              'editor-toolbar',
              { hidden: !this.state[Context.CONTEXT_TYPE_TEXT] }
            )}
            key="editor-toolbar"
            ref="editor-toolbar"
          />

          <LinkToolbar
            context={this.props.context}
            project={this.props.project}
            page={this.props.page}
            onMouseDown={this.handleMouseDownOnMenuBar}
            className={classNames(
              'link-toolbar',
              { hidden: !this.state[Context.CONTEXT_TYPE_LINK] }
            )}
            key="link-toolbar"
            ref="link-toolbar"
          />

          {
            (hasMainContext && this.state[Context.CONTEXT_TYPE_LINK])
              ? <span className="separator vertical"></span>
              : null
          }

          <VersionToolbar
            context={this.props.context}
            className={classNames(
              'version-toolbar',
              'v-align',
              { hidden: !this.state[Context.CONTEXT_TYPE_VERSION] }
            )}
          />

        </div>
      </div>


      <GenevaButton onClick={this.handleChangeToolsPanelVisibility}
        className={`
          button
          plain
          float-right
          ${paneState.articleToolsPanelOpen ? 'active' : ''}
        `}
      >
        <ToolsSidebarIcon width={24} height={24} />
      </GenevaButton>
    </div>)

  }
}
